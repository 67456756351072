import React from "react"
import HeaderWithoutAside from "./header-without-aside"
import MenuSocial from "./menu-social"
import { Grid, Themed } from "theme-ui"

const Layout = ( props ) => {

  return (
    <Themed.root>
      <Grid
        as={`div`}
        gap={0}
        sx={{
          gridTemplateColumns: `1fr`,
          gridTemplateRows: [ `4rem auto 4rem`,
                              `4rem auto 4rem`,
                              `4rem auto 4rem`,
                              `4rem 1fr 4.5rem`,
                              `4rem 1fr 4.5rem`,
                              `4rem 1fr 4.5rem`
                            ],
          gridTemplateAreas:[`"header" "main" "footer"`],
          width: `100vw`,
          ".site-header": {
            gridArea: `header`,
            px: [`initial`, `initial`, `initial`, 4, 4, 4],
          },
          main: {
            gridArea: `main`,
            mt: 4,
            px: [3, 3, 3, 4, 4, 4],
          },
          ".menu-social": {
            gridArea: `footer`,
            px: [3, 3, 3, 4, 4, 4],
            p: {
              mr: 1,
            },
            ".content-menu": {
              justifyContent: `flex-end`,
            },
          }
        }}
      >
        <HeaderWithoutAside />
        {props.children}
        <MenuSocial />
      </Grid>
    </Themed.root>
  )

}

export default Layout